import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { BrowserRouter } from "react-router-dom"
import {
  CssBaseline,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core"
import {
  ShoppingCartProvider,
} from "contexts"
import App from "./app"

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      contrastText: "#fff",
      dark: "#1976D2",
      light: "#BBDEFB",
      main: "#3e83a7ff",
    },
    secondary: {
      contrastText: "#fff",
      dark: "#1976D2",
      light: "#BBDEFB",
      main: "#3e83a7ff",
    },
  },
})


const Root = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
            <ShoppingCartProvider>
                <CssBaseline />
                <GlobalStyle />
                <BrowserRouter>
                  <App />
                </BrowserRouter>
            </ShoppingCartProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

const GlobalStyle = createGlobalStyle`
  #root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`

export default Root
