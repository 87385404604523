import React from "react"
import AppForUsers from "./app-for-users"
import { AuthProvider} from "contexts"
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './ducks/Appoitment/store';



function App(props) {
  return (
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <AuthProvider>
      <AppForUsers {...props} />
    </AuthProvider>
    </PersistGate>
    </Provider>
  )
}

export default App
