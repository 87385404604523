import { legacy_createStore as createStore} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import appointmentReducer from './reducer';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'appointments',
    storage,
};

const persistedReducer = persistReducer(persistConfig, appointmentReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

export default { store, persistor };