import React, { Suspense, lazy, useEffect, useState } from "react"
import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import { LinearProgress } from "@material-ui/core"
import { navigationRoutes as navRoutes, CHOOSE_PROFESSIONAL, CHOOSE_DATE, CLIENT, CHOOSE_SERVICE, LOGIN, RESERVATION} from "routes"
import { useAuth } from "hooks";


const MainPage = lazy(() => import("pages/main"));
const Scheduling = React.lazy(() => import("pages/scheduling"));
const ChooseProcedure = React.lazy(() => import("pages/choose-procedure"))
const ChooseDate = React.lazy(() => import("pages/choose-date"));
const Schedule = React.lazy(() => import("pages/schedule"));
const Client = React.lazy(() => import("pages/client"));
const Login = React.lazy(() => import("pages/login"));
const Reservation = React.lazy(() => import("pages/admin/scheduleWeek"));

function AppForUsers() {
  const location = useLocation()

  const { userInfo, setUserInfo } = useAuth()
  const [didCheckUserIn, setDidCheckUserIn] = useState(true)
  const { isUserLoggedIn } = userInfo

  useEffect(() => {
    localStorage.setItem(
      "currentUserId",
      isUserLoggedIn ? userInfo.user?.uid : "guest"
    )
  }, [isUserLoggedIn])

  if (!didCheckUserIn) {
    return <LinearProgress />
  }


  if (!isUserLoggedIn && location.pathname === navRoutes.RESERVATIONS) {
    return <Navigate to={navRoutes.LOGIN} />
  }

  return (
   
      <Suspense fallback={<LinearProgress />}>
      <Routes>
      <Route path={'/'} element={<Scheduling/>}/>
        <Route path={'/'} element={<MainPage />}>
        <Route path={`/admin/${LOGIN}`} element={<Login/>}/>
        <Route path={`/admin/${RESERVATION}`} element={<Reservation />}/>
          <Route path={'/:name'} element={<Schedule/>}/>
          <Route path={`/:name/${CHOOSE_SERVICE}`} element={<ChooseProcedure />}/>
          <Route path={`/:name/${CHOOSE_DATE}`} element={<ChooseDate />}/>
          <Route path={`/:name/${CHOOSE_DATE}/${CLIENT}`} element={<Client />}/>
        </Route>
      </Routes>
    </Suspense>
    
  )
}

export default AppForUsers
