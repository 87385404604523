// Actions
const SET_ESTABLISHMENT = 'appointment/SET_ESTABLISHMENT';
const SET_PROFESSIONAL = 'appointment/SET_PROFESSIONAL';
const SET_PROCEDURE = 'appointment/SET_PROCEDURE';
const SET_DATE_TIME = 'appointment/SET_DATE_TIME';
const SET_CLIENT = 'appointment/SET_CLIENT';

export const setEstablishment = establishment => ({
  type: SET_ESTABLISHMENT,
  establishment
});

export const setProfessional = professional => ({
  type: SET_PROFESSIONAL,
  professional
});

export const setProcedure = procedure => ({
  type: SET_PROCEDURE,
  procedure
});

export const setDateTime = dateTime => ({
  type: SET_DATE_TIME,
  dateTime
});

export const setCliente = client => ({
  type: SET_CLIENT,
  client
});


// Reducer
const initialState = {
    establishment: '',
    professional: '',
    procedure: '',
    dateTime: '',
    client:''
};
  
export default function appointmentReducer(state = initialState, action) {
    switch (action.type) {
      case SET_ESTABLISHMENT:
        return { ...state, establishment: action.establishment, client: '' };
      case SET_PROFESSIONAL:
        return { ...state, professional: action.professional, client: ''  };
      case SET_PROCEDURE:
        return { ...state, procedure: action.procedure, client: ''  };
      case SET_DATE_TIME:
        return { ...state, dateTime: action.dateTime, client: ''  };
      case SET_CLIENT:
          return { ...state, client: action.client };
      default:
        return state;
    }
  }
